import React from "react";
import classes from "./Toolbar.css";
import Button from "../UI/Button";

import Logo from "../../assets/Logo/Logo.png";

const toolbar = ({onClickAboutMe, onClickContact}) => {
    return (
        <header className={classes.Header}>
            <div className={classes.Logo}>
                <img src={Logo}/>

            </div>
            <nav className={classes.NavItems}>
                <Button btnType="NavButton" onClicked={onClickContact}>Contact</Button>
                <Button btnType="NavButton" onClicked={onClickAboutMe}>About Me</Button>
            </nav>            
        </header>

    );
}

export default toolbar;