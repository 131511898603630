import React from "react";
import classes from "./Button.css";

const button = (props) => (
    <a
        className={[classes.Button, classes[props.btnType]].join(" ")}
        disabled={props.disabled}
        href={props.clicked}
        target="_blank"
        onClick={props.onClicked}
        rel="noopener noreferrer">
        {props.children}
    </a>
);

export default button;