import React, { Component } from "react";
import classes from "./project.css";
import Button from "../../components/UI/Button";

class Project extends Component {

    render() {
        let classAlign = classes.ImgContainerLeft;
        let direction = null;

        if (this.props.type === "right") {
            classAlign = classes.ImgContainerRight;
            direction = classes.ProjectReverse;
        }

        return (
            <div className={[classes.Project, direction].join(" ")}>
                <img className={classAlign} src={this.props.imgPath} alt={" "} />
                <div className={classes.WorkTitle}>
                    {this.props.children}
                    <Button clicked={this.props.linkTo}>TAKE A LOOK</Button>
                </div>
            </div>
        )
    }
};



export default Project;