import React from "react";
import ProjectMobile from "../../container/ProjectMobile/proMobile";
import classes from "./worksMobile.css";


import editorial from "../../assets/projects/project_editorial2.jpg";
import game from "../../assets/projects/project_game2.jpg";
import paper from "../../assets/projects/project_paper2.jpg";
import program from "../../assets/projects/project_program2.jpg";
import signal from "../../assets/projects/project_signal2.jpg";



const worksMobile = () => {
    return (
        <div className={classes.Works}>
            <ProjectMobile type="left" imgPath={program} linkTo={"https://www.behance.net/gallery/31904121/Graphic-Program-Norman-Foster"}>
                <p>Graphic Program</p> <p>Norman Foster</p>   
            </ProjectMobile>
            <ProjectMobile type="right" imgPath={editorial} linkTo={"https://www.behance.net/gallery/60671861/Editorial-IND-Magazine"} >
                <p>Editorial Design</p> <p>IND Magazine</p>    
            </ProjectMobile>
            <ProjectMobile type="left" imgPath={signal} linkTo={"https://www.behance.net/gallery/76936273/Signage-System-Buenos-Aires-BUS"}>
                <p>Signage Program</p> <p>Buenos Aires BUS</p>    
            </ProjectMobile>
            <ProjectMobile type="right" imgPath={game} linkTo={"https://www.behance.net/gallery/35568343/Redesign-Card-Game"}>
                <p>Redisign</p> <p>Card Game "Munchkin"</p>
            </ProjectMobile> 
            <ProjectMobile type="left" imgPath={paper} linkTo={"https://www.behance.net/gallery/55888115/Corporate-Image-Youth-Clothing-VIICIOS"}>
                <p>Institutional Brand</p> <p>VIIcios</p>
            </ProjectMobile>           
        </div>
    )
};

export default worksMobile;