import React, { Component } from 'react';

import Aux from "./hoc/Auxiliar";
import Viewport from "./components/Viewport/ScreenSize";


class App extends Component {

  render() {

    return (
      <Aux >
        <Viewport />
      </Aux>
    )
  }
};

export default App;
