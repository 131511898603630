import React, { Component } from 'react';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';

import classes from "./Landscape.css";
import Background from "../../assets/BackgroundImg/B.svg";
import Anima from "../../assets/Animation/rotate-mobile.gif";

class landscapeView extends Component {

    render() {
        const styles = {
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        };

        return (

            <Parallax ref={ref => (this.parallax = ref)} pages={1} >

                <ParallaxLayer offset={0} speed={1} style={{ backgroundImage: `url(${Background})`, backgroundSize: "cover" }} />

                <ParallaxLayer
                    offset={0}
                    speed={1}
                    style={styles}>
                    <div className={classes.RotateText}>
                        <p>Please rotate your devise</p>
                        <div className={classes.Animation}>
                            <img src={Anima}></img>
                        </div>
                    </div>
                </ParallaxLayer>

            </Parallax>
        )

    }
};

export default landscapeView;