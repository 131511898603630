import React, { Component } from "react";
import classes from "./triangles.css";




 class triangle extends Component {
    render() {
        let figure = null;

        switch (this.props.name) {
            case ("triA"):
                figure = <svg className={classes.TrianA} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 388">
                    <polygon points="0 52.1 640 243.4 403 388 " />
                </svg>;
                break;
            case ("triB"):
                figure = <svg className={classes.TrianB} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 388">
                    <polygon points="0 0 640 386.6 85.5 386.6 " />
                </svg>;
                break;
            case ("triC"):
                figure = <svg className={classes.TrianC} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 388">
                    <polygon points="239.7 0 0.4 388 479.3 388 " />
                </svg>;
                break;
            case ("triD"):
                figure = <svg className={classes.TrianD} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 388">
                    <polygon points="640 0.7 0 387 551.9 387 " />
                </svg>;
                break;
            case ("triE"):
                figure = <svg className={classes.TrianE} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 388">
                    <polygon points="640 52 1.6 246.1 236.8 388 " />
                </svg>;
                break;
            default: figure = null;

        }
        return figure;
    }

};


export default triangle;