import React from 'react';
import { useSpring, animated } from 'react-spring';
import classes from "./Title.css";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const transBg = (x, y) => `translate3d(${x / 40}px,${y / 40}px,0)`
const transFg = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`

const TitleAnimated = ({bgTitle, fgTitle}) => {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 240 } }));
  return (
    <div className={classes.Container} onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <animated.div className={classes.BgTitle} style={{ transform: props.xy.interpolate(transBg) }}>
          {bgTitle}
      </animated.div>
      <animated.div className={classes.FgTitle} style={{ transform: props.xy.interpolate(transFg) }}>
          {fgTitle}
      </animated.div>
    </div>
  )
};

export default TitleAnimated;