import React, { Component } from "react";
import classes from "./proMobile.css";

class ProjectMobile extends Component {

    render() {
        let classAlign = classes.ImgContainerLeft;
        let direction = null;

        if (this.props.type === "right") {
            classAlign = classes.ImgContainerRight;
            direction = classes.ProjectReverse;
        }

        return (
            <div className={[classes.Project, direction].join(" ")}>
                <a href={this.props.linkTo} target="_blank"> 
                    <img className={classAlign} src={this.props.imgPath} alt={" "} />
                </a>

                <div className={classes.WorkTitle}>
                    {this.props.children}
                </div>
            </div>
        )
    }
};



export default ProjectMobile;