import React, { Component } from 'react';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';

import Works from "../../components/worksMobile/worksMobile";
import Toolbar from "../../components/Navigation/Toolbar";
import classes from "./BackTablet.css"
import Triangle from "../crown/triangles";

import About from "../../components/AboutMe/AboutMe";
import Contact from "../../components/ContactMe/Contact";
import Background from "../../assets/BackgroundImg/B.svg";

import Title from "../../components/TitleAnimated/Title";

class backTablet extends Component {
    render() {
        const styles = {
            fontFamily: 'Days One, sans-serif',
            fontSize: 14,
            lineHeight: '10px',
            color: 'white',
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        };

        return (
            <Parallax ref={ref => (this.parallax = ref)} pages={4} >

                <ParallaxLayer offset={3} speed={-1} style={{ backgroundImage: `url(${Background})`, backgroundSize: "cover" }} />

                <ParallaxLayer offset={3} speed={2.5} style={{ backgroundColor: '#162c3b' }} />

                <ParallaxLayer
                    offset={3.885}
                    speed={-0.5}>
                    <Triangle name="triB" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.862}
                    speed={-1.6}>
                    <Triangle name="triC" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.885}
                    speed={-2.9}>
                    <Triangle name="triD" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.91}
                    speed={1}>
                    <Triangle name="triE" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.91}
                    speed={1}>
                    <Triangle name="triA" />
                </ParallaxLayer>

                <ParallaxLayer offset={0} speed={1} style={{ backgroundColor: '#043f53', boxShadow: "0px 10px 50px 0px rgba(0,0,0,0.26)" }} />
                <ParallaxLayer offset={1} speed={0.2} style={{ backgroundColor: "#012e47", boxShadow: "0px 10px 50px 0px rgba(0,0,0,0.26)" }} />
                <ParallaxLayer offset={2} speed={0.2} style={{ backgroundColor: "#042334", boxShadow: "0px 10px 50px 0px rgba(0,0,0,0.26)" }} />

                <ParallaxLayer
                    offset={0}
                    speed={-1.3}>
                    <div className={classes.Container}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.1}
                    speed={-1.353}>
                    <div className={classes.ContainerA}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.9}
                    speed={-0.9}>
                    <div className={classes.ContainerB}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1}
                    speed={-1.351}>
                    <div className={classes.ContainerC}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1}
                    speed={-1.403}>
                    <div className={classes.ContainerD}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2}
                    speed={-1.903}>
                    <div className={classes.ContainerE}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2}
                    speed={-1.976}>
                    <div className={classes.Container1}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-1.308}>
                    <div className={classes.Container2}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.9}
                    speed={-0.977}>
                    <div className={classes.Container3}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.1}
                    speed={-1.754}>
                    <div className={classes.Container4}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1}
                    speed={-1.462}>
                    <div className={classes.Container5}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>


                <ParallaxLayer
                    offset={3.75}
                    speed={1}>
                    <div className={classes.CircleX}></div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.66}
                    speed={1}>
                    <div className={classes.CircleY}></div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.75}
                    speed={1}>
                    <div className={classes.CircleZ}></div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={1}>
                    <div className={classes.Intro}>
                        <Toolbar
                            onClickContact={() => this.parallax.scrollTo(3)}
                            onClickAboutMe={() => this.parallax.scrollTo(2.1)} />
                        <p className={classes.TitleIntro}>Good Design<br />requires Good<br />Comunication</p>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.1}
                    speed={1}
                    style={styles}>
                    <Works />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.4}
                    speed={1}
                    style={styles}>
                    <About />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.06}
                    speed={3.5}>
                    <Contact />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.2}
                    speed={1}>
                    <Title bgTitle="IF YOU WANT" fgTitle="TO CONTACT ME" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2}
                    speed={1.6}>
                    <Title bgTitle="LIKE TO KNOW" fgTitle="ABOUT ME" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0.999}
                    speed={1.4}>
                    <Title bgTitle="A HINT OF" fgTitle="WHAT I DO" />
                </ParallaxLayer>

            </Parallax>
        )

    }
};

export default backTablet;