import React from "react";
import classes from "./AboutMe.css";


const about = () => {
    return (
        <div className={classes.AboutWrap}>
            <div className={classes.Picture}></div>
            <p>
                Hello, my name is Daniela and I am a graphic designer graduated from the U.A.I university in Buenos Aires.
                I finished my studies in 2016 and since that year I work as a freelance designer.
                What attracted me to this profession was the need to combine creativity with problem solving. 
                I think that combination is the most challenging and fun of my job.<br/> 
                My motivation is to be better at what I do, so I always seek to learn new technologies and improve my skills. 
                That led me to get to know and get passionate about Web design so I began to study that branch of design 
                self-taught while continuing my university studies. Working with paper has its charm and I played with 
                it since I was a child, the new challenge is the screens and their technology.<br/>
                I think that a good design work requires good communication, but not only from the graphic piece to the 
                receiver, but also between the designer and the client. That is how we can solve the problem in the best way and everyone 
                is happy: the client gets a lovely result and I hand over a professional job that makes me proud. Let's work together!
            </p>
        </div>
    )
};

export default about;

