import React, { Component } from 'react';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';

import Works from "../../components/worksMobile/worksMobile";
import Toolbar from "../../components/Navigation/Toolbar";
import classes from "./BackMobile.css"
import Triangle from "../crown/triangles";

import About from "../../components/AboutMe/AboutMe";
import Contact from "../../components/ContactMe/Contact";
import Background from "../../assets/BackgroundImg/B.svg";
import Logo from "../../assets/Logo/Logo-corona.svg";

class backMobile extends Component {

    render() {

        const styles = {
            fontFamily: 'Days One, sans-serif',
            fontSize: 14,
            lineHeight: '10px',
            color: 'white',
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        };

        return (

            <Parallax ref={ref => (this.parallax = ref)} pages={4} style={{height: "100vh"}} >

                <ParallaxLayer offset={3} speed={-1} style={{ backgroundImage: `url(${Background})`, backgroundSize: "cover" }} />

                <ParallaxLayer
                    offset={1}
                    speed={-0.4}>
                    <p className={classes.TitleBg}>A HINT OF</p>
                    <p className={classes.TitleFg}>WHAT I DO</p>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.999}
                    speed={-0.2}>
                    <p className={classes.TitleBg}>IF YOU WANT</p>
                    <p className={classes.TitleFg}>TO CONTACT ME</p>
                </ParallaxLayer>


                <ParallaxLayer offset={3} speed={2.5} style={{ backgroundColor: '#243B4A' }} />

                <ParallaxLayer
                    offset={2}
                    speed={-1.928}>
                    <Triangle name="triB" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.3}
                    speed={-1.618}>
                    <Triangle name="triC" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.5}
                    speed={-1.429}>
                    <Triangle name="triD" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.937}
                    speed={1.05}>
                    <Triangle name="triE" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.937}
                    speed={1}>
                    <Triangle name="triA" />
                </ParallaxLayer>
                
                <ParallaxLayer
                    offset={2}
                    speed={2.8}>
                    <p className={classes.TitleBg}>LIKE TO KNOW</p>
                    <p className={classes.TitleFg}>ABOUT ME</p>
                </ParallaxLayer>

                

                <ParallaxLayer offset={0} speed={1} style={{ backgroundColor: '#243B4A', boxShadow: "0px 10px 50px 0px rgba(0,0,0,0.26)" }} />
                <ParallaxLayer offset={1} speed={0.1} style={{ backgroundColor: "#2E3850", boxShadow: "0px 10px 50px 0px rgba(0,0,0,0.26)" }} />
                <ParallaxLayer offset={2.5} speed={0.5} style={{ backgroundColor: "#2E3850", boxShadow: "0px 10px 50px 0px rgba(0,0,0,0.26)" }} />

                <ParallaxLayer
                    offset={0}
                    speed={-1.312}>
                    <div className={classes.Container}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.1}
                    speed={-1.387}>
                    <div className={classes.ContainerA}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.9}
                    speed={-0.956}>
                    <div className={classes.ContainerB}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1}
                    speed={-1.406}>
                    <div className={classes.ContainerC}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1}
                    speed={-1.437}>
                    <div className={classes.ContainerD}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2}
                    speed={-1.936}>
                    <div className={classes.ContainerE}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2}
                    speed={-1.976}>
                    <div className={classes.Container1}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={-1.314}>
                    <div className={classes.Container2}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.9}
                    speed={-0.997}>
                    <div className={classes.Container3}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.1}
                    speed={-1.794}>
                    <div className={classes.Container4}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1}
                    speed={-1.471}>
                    <div className={classes.Container5}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>


                <ParallaxLayer
                    offset={0}
                    speed={1}>
                    <div className={classes.Intro}>
                        <Toolbar
                            onClickContact={() => this.parallax.scrollTo(3)}
                            onClickAboutMe={() => this.parallax.scrollTo(2)} />
                        <img className={classes.Logo} src={Logo} />
                        <p className={classes.TitleIntro}>Good Design<br />requires Good<br />Comunication</p>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.2}
                    speed={2.5}
                    style={styles}>
                    <Works />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.06}
                    speed={3.5}>
                    <Contact/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2}
                    speed={2.8}>
                    <About />
                </ParallaxLayer>

                

            </Parallax>
        )

    }
};

export default backMobile;