import React from "react";
import { Transition } from 'react-spring/renderprops';
import { config } from 'react-spring'

import classes from "./Intro.css";

const Intro = () => {
    return (
        <div className={classes.Intro}>
            <Transition
                items={{ key: 1, text: "Good Design requires" }} keys={item => item.key}
                from={{ transform: 'translate3d(-100%,0,0)', opacity: 0 }}
                enter={{ transform: 'translate3d(-10%,0px,0)', opacity: 1 }}
                config={config.gentle}>
                {item => props => <div style={props}>{item.text}</div>}
            </Transition>

            <Transition
                items={{ key: 2, text: "Good Comunication" }} keys={item => item.key}
                from={{ transform: 'translate3d(100%,6rem,0)', opacity: 0 }}
                enter={{ transform: 'translate3d(10%,6rem,0)', opacity: 1 }}
                config={config.gentle}>
                {item => props => <div style={props}>{item.text}</div>}
            </Transition>
        </div>

    )
};

export default Intro;
