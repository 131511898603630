import React from "react";

import classes from "./Contact.css";

import Behance from "../../assets/Icons/behance2.png";
import Instagram from "../../assets/Icons/instagram2.png";
import Linkedin from "../../assets/Icons/linkedin2.png";
import Mail from "../../assets/Icons/mail2.png";
import Whatsapp from "../../assets/Icons/whatsapp2.png"

const contact = () => {
    return (
        <div className={classes.ContactWrap}>
            <div className={classes.SocialMedia}>
                <a href="https://www.behance.net/lethia" target="_blank" rel="noopener noreferrer">
                    <img src={Behance} alt="My Behance"/>
                </a>
                <a href="https://www.instagram.com/danu.reina/" target="_blank" rel="noopener noreferrer">
                    <img src={Instagram} alt="My Instagram"/>
                </a>
                <a href="https://www.linkedin.com/in/daniela-m-reina/" target="_blank" rel="noopener noreferrer">
                    <img src={Linkedin} alt="My Linkedin"/>
                </a>
            </div>
            <div className={classes.ContactMe}>
                <img src={Whatsapp} alt=" "/>
                <p>(+54) 011 - 3350 - 6147</p>
                <img src={Mail}  alt=" "/>
                <a href="mailto: dg@danielareina.me">dg@danielareina.me</a>
            </div>
        </div>

    )
};

export default contact;