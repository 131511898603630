import React, { Component } from 'react';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';

import Works from "../../components/works/works";
import Toolbar from "../../components/Navigation/Toolbar";
import classes from "./BackDesktop.css"
import Triangle from "../crown/triangles";
import About from "../../components/AboutMe/AboutMe";
import Contact from "../../components/ContactMe/Contact";
import Background from "../../assets/BackgroundImg/B.svg";
import Title from "../../components/TitleAnimated/Title";
import Intro from "../../components/Intro/Intro";

class backDesktop extends Component {
    render() {
        const styles = {
            fontFamily: 'Days One, sans-serif',
            fontSize: 14,
            lineHeight: '10px',
            color: 'white',
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        };

        return (
            <Parallax ref={ref => (this.parallax = ref)} pages={4} >

                <ParallaxLayer offset={3} speed={-1} style={{ backgroundImage: `url(${Background})`, backgroundSize: "cover" }} />

                <ParallaxLayer offset={3} speed={2.5} style={{ backgroundColor: '#162c3b' }} />

                <ParallaxLayer
                    offset={3.689}
                    speed={-0.5}>
                    <Triangle name="triB" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.64}
                    speed={-1.6}>
                    <Triangle name="triC" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.688}
                    speed={-2.9}>
                    <Triangle name="triD" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.76}
                    speed={1}>
                    <Triangle name="triE" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.76}
                    speed={1}>
                    <Triangle name="triA" />
                </ParallaxLayer>


                <ParallaxLayer offset={0} speed={1} style={{ backgroundColor: '#043f53', boxShadow: "0px 10px 50px 0px rgba(0,0,0,0.26)" }} />
                <ParallaxLayer offset={1} speed={0.1} style={{ backgroundColor: "#012e47", boxShadow: "0px 10px 50px 0px rgba(0,0,0,0.26)" }} />
                <ParallaxLayer offset={2} speed={0.02} style={{ backgroundColor: "#042334", boxShadow: "0px 10px 50px 0px rgba(0,0,0,0.26)" }} />

                <ParallaxLayer
                    offset={3.77}
                    speed={0.2}>
                    <div className={classes.Container}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.8}
                    speed={-0.678}>
                    <div className={classes.ContainerA}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2}
                    speed={-1.24}>
                    <div className={classes.ContainerB}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.24}
                    speed={-1.15}>
                    <div className={classes.ContainerC}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.476}
                    speed={-0.9}>
                    <div className={classes.ContainerD}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2}
                    speed={-1.763}>
                    <div className={classes.ContainerE}>
                        <div className={classes.Circle}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.93}
                    speed={1}>
                    <div className={classes.Container1}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.78}
                    speed={-1.78}>
                    <div className={classes.Container2}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.605}
                    speed={-0.853}>
                    <div className={classes.Container3}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.605}
                    speed={-1.173}>
                    <div className={classes.Container4}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.78}
                    speed={-2.6}>
                    <div className={classes.Container5}>
                        <div className={classes.Circle2}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.77}
                    speed={1}>
                    <div className={classes.ContainerX}>
                        <div className={classes.Circle3}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.7}
                    speed={1}>
                    <div className={classes.ContainerY}>
                        <div className={classes.Circle3}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.77}
                    speed={1}>
                    <div className={classes.ContainerZ}>
                        <div className={classes.Circle3}></div>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={1}
                    style={styles}>
                    <Toolbar
                        onClickContact={() => this.parallax.scrollTo(3)}
                        onClickAboutMe={() => this.parallax.scrollTo(2.3)} />
                    <Intro />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.5}
                    speed={1}
                    style={styles}>
                    <Works />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.73}
                    speed={1}
                    style={styles}>
                    <About />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={3.06}
                    speed={3.5}>
                    <Contact />
                    <Title bgTitle="IF YOU WANT" fgTitle="TO CONTACT ME" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={2.4}
                    speed={1.05}>
                    <Title bgTitle="LIKE TO KNOW" fgTitle="ABOUT ME" />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0.9}
                    speed={1.4}>
                    <Title bgTitle="A HINT OF" fgTitle="WHAT I DO" />
                </ParallaxLayer>


            </Parallax>
        )

    }
};

export default backDesktop;